import http from '../../../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD_UTESPECIALIZADA}`

const apiGetProveedores = (query) => http.get(`${api}/dashboard/proveedores`, { params: { ...query } })
const apiListNotaTecnicaInfo = (query) => http.get(`${api}/dashboard/nota-tecnica-info`, { params: { ...query } })

export {
  apiGetProveedores,
  apiListNotaTecnicaInfo
}
