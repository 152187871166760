<template>
  <div class="box">

    <div class="mb-4 relative">
      <!--  -->
      <div class="accordion-item col-span-12 text-xs">
        <div id="faq-accordion-content" class="border-b border-gray-200 dark:border-dark-5 accordion-header flex justify-between col-span-2">
          <button
            class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#faq-accordion-collapse-1"
            aria-expanded="true"
            aria-controls="faq-accordion-collapse-1"
          >
            <div class="flex flex-col sm:flex-row items-center p-4">
              <h4 class="mr-auto capitalize text-base font-medium font-bold">Nota Técnica - Tabla Comparativa</h4>
            </div>
          </button>
        </div>
        <div id="faq-accordion-collapse" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content" data-bs-parent="#faq-accordion">
          <div class="accordion-body text-gray-700 m-4 dark:text-gray-600 leading-relaxed">

            <!-- Filtros -->
            <section class="w-full pb-4">
              <div class="grid grid-cols-12 gap-x-4 gap-y-2">
                <div class="col-span-12 sm:col-span-2">
                  <label for="fechaReportadaNotaTecnica" class="block text-sm font-medium text-gray-700">Periodo Rad:</label>
                  <select
                    v-model="filters.fechaReportada"
                    class="form-select form-select-sm mt-1"
                    name="fechaReportadaNotaTecnica"
                    id="fechaReportadaNotaTecnica"
                  >
                    <option
                      v-for="(value, k) in listFechasReportadas"
                      :key="k"
                      :value="value"
                    >
                      {{value}}
                    </option>
                  </select>
                </div>
                <div class="col-span-12 sm:col-span-2">
                  <label for="porcentaje" class="block text-sm font-medium text-gray-700">Porcentaje:</label>
                  <span class="p-input-icon-right w-full">
                    <i class="pi pi-percentage text-sm" />
                    <InputNumber
                      id="porcentaje"
                      v-model="filters.porcentaje"
                      class="p-inputtext-sm mt-1 w-full"
                      style="height: 30px;"
                      :min="0"
                      :max="100"
                    />
                  </span>
                </div>
                <div class="col-span-12 sm:col-span-7">
                  <label for="proveedores" class="block text-sm font-medium text-gray-700">Proveedores:</label>
                  <MultiSelect
                    id="proveedores"
                    v-model="filters.proveedores"
                    :options="listProveedores"
                    optionLabel="nombreCorto"
                    optionValue="id"
                    placeholder="Selecciona una o más opciones..."
                    :filter="true"
                    class="w-full p-inputtext-xs mt-1 p-multiselect-custom"
                    display="chip"
                  />
                </div>
                <div class="col-span-12 sm:col-span-1 flex items-end justify-start">
                  <Button
                    label="Buscar"
                    icon="pi pi-search"
                    class="p-button-xs"
                    @click="handleSearch"
                  />
                </div>
              </div>
            </section>

            <div v-if="cargando" style="width:100%; height: 300px" class="rounded-md icono-carga flex justify-center items-center relative">
              <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
            </div>

            <div class="col-span-12 lg:col-span-12 pb-4" v-else>

              <DataTable
                :value="dataNotaTecnica"
                :paginator="true"
                :rows="50"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5,10,25,50,100]"
                currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
                :responsiveLayout="windowSize > 768 ? 'scroll' : 'stack'"
                breakpoint="768px"
                showGridlines
                class="p-datatable-sm text-right text-xs mt-2"
              >
                <ColumnGroup type="header">
                  <Row>
                    <Column header="Grupo" style="font-weight: bold; width: 220px;" field="grupo"/>
                    <Column header="Tecnología" style="width: 375px; font-weight: bold" field="tecnologia"/>
                    <Column header="Cant.NT" style="font-weight: bold" field="eventoMes"/>
                    <Column header="Vlr.Ud.NT" style="font-weight: bold" field="costoMedio"/>
                    <Column header="Vlr.Total.NT" style="font-weight: bold" field="costoMes"/>
                    <Column header="Cant.Real (RIPS)" style="font-weight: bold" field="eventoMesActual"/>
                    <Column header="Vlr.Ud.Real (RIPS)" style="font-weight: bold" field="costoMedioActual"/>
                    <Column header="Vlr.Total.Real (RIPS)" style="font-weight: bold" field="costoMesActual"/>
                    <Column header="%VlrReal/VlrNT" style="font-weight: bold" field="percentRealvsNota"/>
                    <Column header="Vlr.Total.Rep" style="font-weight: bold" field="costoMesPorcentaje"/>
                  </Row>
                </ColumnGroup>
                <Column header="Grupo" field="grupo" style="width: 220px; background:#eff4f8;"></Column>
                <Column header="Tecnología" field="tecnologia" style="width: 375px; background:#eff4f8;"></Column>
                <Column header="Cant.NT" field="eventoMes" style="background:#eff4f8;">
                  <template #body="{data}">
                    {{ $h.formatNumber(data.eventoMes, 0) }}
                  </template>
                </Column>
                <Column header="Vlr.Ud.NT" field="costoMedio" style="background:#eff4f8;">
                  <template #body="{data}">
                    {{ $h.formatCurrency(data.costoMedio, 0) }}
                  </template>
                </Column>
                <Column header="Vlr.Total.NT" field="costoMes" style="background:#eff4f8;">
                  <template #body="{data}">
                    {{ $h.formatCurrency(data.costoMes, 0) }}
                  </template>
                </Column>
                <Column header="Cant.Real" field="eventoMesActual" style="background:#eae9e9;">
                  <template #body="{data}">
                    {{ $h.formatNumber(data.eventoMesActual, 0) }}
                  </template>
                </Column>
                <Column header="Vlr.Ud.Real" field="costoMedioActual" style="background:#eae9e9;">
                  <template #body="{data}">
                    {{ $h.formatCurrency(data.costoMedioActual, 0) }}
                  </template>
                </Column>
                <Column header="Vlr.Total.Real" field="costoMesActual" style="background:#eae9e9;">
                  <template #body="{data}">
                    {{ $h.formatCurrency(data.costoMesActual, 0) }}
                  </template>
                </Column>
                <Column header="%VlrReal/VlrNT" field="percentRealvsNota" style="background:#E2EFDA;">
                  <template #body="{data}">
                    {{ data.percentRealvsNota ? data.percentRealvsNota : 0 }}%
                  </template>
                </Column>
                <Column header="Vlr.Total.Rep" field="costoMesPorcentaje" style="background:#E2EFDA;">
                  <template #body="{data}">
                    {{ $h.formatCurrency(data.costoMesPorcentaje, 0) }}
                  </template>
                </Column>
                <ColumnGroup type="footer">
                  <Row>
                    <Column footer="Totales:" :colspan="2" footerStyle="text-align:right"/>
                    <Column :footer="totalCantNt" />
                    <Column footer="" />
                    <Column :footer="totalValorTotalNt" />
                    <Column :footer="totalCantReal" />
                    <Column footer="" />
                    <Column :footer="totalValorTotalReal" />
                    <Column footer="" />
                    <Column :footer="totalValorTotalReportado" />
                  </Row>
                </ColumnGroup>
              </DataTable>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import {
  apiGetProveedores, apiListNotaTecnicaInfo
} from './services'
import dayjs from 'dayjs'
import unique from 'lodash/uniq'
import currency from 'currency.js'
import { helper } from '../../../../../../../utils/helper'

export default {
  name: 'cardNotaTecnicaInfo',
  components: {},
  props: {
    typeCompany: {
      type: Number,
      default: 1
    }
  },
  setup (props) {
    const filters = reactive({
      fechaReportada: dayjs().subtract(1, 'month').format('YYYY-MM'),
      proveedores: [],
      porcentaje: 20,
      typeCompany: props.typeCompany
    })
    const cargando = ref(false)
    const listFechasReportadas = ref([])
    const listProveedores = ref([])
    const dataNotaTecnica = ref([])
    const rutasInfo = ref([
      {
        grupo: 'RIA CARDIOVASCULAR',
        tecnologia: 'RIA HIPERTESIÓN RIESGO ALTO',
        eventoMes: 0,
        costoMedio: 142632,
        costoMes: 29980827,
        eventoMesActual: 0,
        costoMedioActual: 0,
        costoMesActual: 0,
        costoMedioPorcentaje: 0,
        costoMesPorcentaje: 0
      },
      {
        grupo: 'RIA CARDIOVASCULAR',
        tecnologia: 'RIA DIABÉTES RIESGO ALTO',
        eventoMes: 0,
        costoMedio: 316830,
        costoMes: 46984163,
        eventoMesActual: 0,
        costoMedioActual: 0,
        costoMesActual: 0,
        costoMedioPorcentaje: 0,
        costoMesPorcentaje: 0
      },
      {
        grupo: 'RIA MATERNO PERINATAL AC',
        tecnologia: 'RIA MATERNO PERINATAL AC',
        eventoMes: 0,
        costoMedio: 257775,
        costoMes: 28657332,
        eventoMesActual: 0,
        costoMedioActual: 0,
        costoMesActual: 0,
        costoMedioPorcentaje: 0,
        costoMesPorcentaje: 0
      },
      {
        grupo: 'RIA EPOC',
        tecnologia: 'RIA EPOC SEVERO Y MUY SEVERO',
        eventoMes: 0,
        costoMedio: 217714,
        costoMes: 7247525,
        eventoMesActual: 0,
        costoMedioActual: 0,
        costoMesActual: 0,
        costoMedioPorcentaje: 0,
        costoMesPorcentaje: 0
      },
      {
        grupo: 'ENFERMERAS GESTORAS',
        tecnologia: 'RUTA DE RIESGO EPOC SEVERO Y MUY SEVERO',
        eventoMes: 6,
        costoMedio: 3213000,
        costoMes: 19278000,
        eventoMesActual: 0,
        costoMedioActual: 0,
        costoMesActual: 0,
        costoMedioPorcentaje: 0,
        costoMesPorcentaje: 0
      }
    ])
    const windowSize = ref(null)

    /* Obtener fecha */
    const getFechas = () => {
      const fechaInicio = new Date('2021-12')
      const fechaFin = new Date()
      const arrayFechas = []

      while (fechaFin.getTime() >= fechaInicio.getTime()) {
        fechaInicio.setDate(fechaInicio.getDate() + 1)

        arrayFechas.push(dayjs(fechaInicio).format('YYYY-MM'))
      }

      listFechasReportadas.value = unique(arrayFechas)
    }

    /* Obtener los proveedores */
    const fetchProveedores = (typeCompany) => {
      cargando.value = true
      return apiGetProveedores({ typeCompany }).then(async ({ status, data: response }) => {
        listProveedores.value = response
        filters.proveedores = listProveedores.value.map(prov => prov.id)
        cargando.value = false
      }).catch(err => {
        console.error(err.message)
        cargando.value = false
      })
    }

    /* Obtener la info de la nota tecnica */
    const fetchNotaTecnicaInfo = (filters) => {
      cargando.value = true
      return apiListNotaTecnicaInfo(filters).then(({ status, data: response }) => {
        if (filters.typeCompany === 1) {
          dataNotaTecnica.value = response.infoNotaTecnica.concat(rutasInfo.value)
        }

        cargando.value = false
      }).catch(err => {
        console.error(err.message)
        cargando.value = false
      })
    }

    /* Total Valor */
    const totalCantNt = computed(() => {
      let total = 0
      for (const pres of dataNotaTecnica.value) {
        total += pres.eventoMes ? pres.eventoMes : 0
      }

      return helper.formatNumber(total)
    })
    /* Total Valor */
    const totalValorTotalNt = computed(() => {
      let total = 0
      for (const pres of dataNotaTecnica.value) {
        total += pres.costoMes ? pres.costoMes : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    /* Total Valor */
    const totalCantReal = computed(() => {
      let total = 0
      for (const pres of dataNotaTecnica.value) {
        total += pres.eventoMesActual ? pres.eventoMesActual : 0
      }

      return helper.formatNumber(total)
    })
    /* Total Valor */
    const totalValorTotalReal = computed(() => {
      let total = 0
      for (const pres of dataNotaTecnica.value) {
        total += pres.costoMesActual ? pres.costoMesActual : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    /* Total Valor */
    const totalValorTotalReportado = computed(() => {
      let total = 0
      for (const pres of dataNotaTecnica.value) {
        total += pres.costoMesPorcentaje ? pres.costoMesPorcentaje : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /* Realizar busqueda */
    const handleSearch = () => {
      fetchNotaTecnicaInfo(filters)
    }

    onMounted(async () => {
      getFechas()
      await fetchProveedores(filters.typeCompany)
      await fetchNotaTecnicaInfo(filters)
      window.addEventListener('resize', evt => {
        windowSize.value = evt.target.innerWidth
      })
    })

    return {
      filters,
      listProveedores,
      listFechasReportadas,
      handleSearch,
      dataNotaTecnica,
      cargando,
      windowSize,
      totalCantNt,
      totalValorTotalNt,
      totalCantReal,
      totalValorTotalReal,
      totalValorTotalReportado
    }
  }
}
</script>

<style>
.p-multiselect-custom.p-multiselect {
  height: 30px;
}
.p-multiselect-custom.p-multiselect .p-multiselect-label {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem;
}
.p-multiselect-custom.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.20rem 0.25rem !important;
  font-size: 0.6rem;
}
.p-multiselect-custom.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  font-size: 0.75rem;
}
</style>
